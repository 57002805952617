//Link primary
@mixin link-main {
  color: $base-main-link-color;
  text-decoration: $base-main-link-decor;
  transition: all 300ms;

  &:hover {
    color: $base-main-link-hover-color;
    text-decoration: $base-main-link-decor-hover;
  }
}


@mixin link-white {
  color: $base-white-link-color;
  text-decoration: $base-white-link-decor;
  transition: all 300ms;

  &:hover {
    color: $base-white-link-hover-color;
    text-decoration: $base-white-link-decor-hover;
  }
}



//Link second
@mixin link-second {
  color: $base-second-link-color;
  text-decoration: $base-second-link-decor;
  transition: all 300ms;

  &:hover {
    color: $base-second-link-hover-color;
    text-decoration: $base-second-link-decor-hover;
  }
}

@mixin link-js {
  text-decoration: none;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  transition: all 300ms;

  &:hover {
    text-decoration: none;
  }
}

//Link empty, not clickable
@mixin link-empty {
  &, &:hover {
    color: $base-font-color !important;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
  }
}

@mixin grid-vetical-gutter($gutter) {
  margin-bottom: -$gutter;

  & > [class*="col-"],
  .slick-track > [class*="col-"]{
    padding-bottom: $gutter;
  }
}

//vertical alignment
@mixin absolute-center-translate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// absolute vertical and horizontal align. elemet's height should be set
@mixin absolute-center-margin {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
  $f-min: strip-unit($f-min);
  $f-max: strip-unit($f-max);
  $w-min: strip-unit($w-min);
  $w-max: strip-unit($w-max);

  $k: ($f-max - $f-min)/($w-max - $w-min);
  $b: $f-min - $k * $w-min;

  $b: $b + $units;

  @return calc( #{$k} * 100vw + #{$b} );
}

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {

  font-size: $f-min;

  @media (min-width: $w-min) {
    @if ($fallback) {
      font-size: $fallback;
    }
    font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}