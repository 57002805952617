.filter {
  $header-title-color: $theme-main-color;
  $header-handle-color: $theme-main-color;
  $chechbox-label-color: #000;
  $item-bg-color: $theme-secondary-color;

  padding: 10px 0;
  font-size: 0.9rem;
  background-color: $theme-secondary-color;

  & .disabled {
    color: $base-font-color-secondary;
  }

  &__item {
    padding: 14px 10px;
    &--sm-padding {
      padding: 10px;
    }
  }

  /* Header BEGIN */
  &__header {
    display: table;
    width: 100%;

    //make whole header clickable. Using if property has dropDown type
    &[data-filter-drop-handle] {
      cursor: pointer;
    }
  }
  &__title {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
    color: $header-title-color;
    text-transform: uppercase;
    font-size: 0.9rem;
    padding-right: 5px;
    &--grey {
      color: $base-font-color;
    }
  }
  &__handle {
    position: relative;
    display: table-cell;
    width: 8px;
    text-align: right;
    vertical-align: middle;

    cursor: pointer;

    &-ico {
      position: relative;
      height: 100%;
      width: 8px;
      display: block;
      fill: $header-handle-color;
      color: $header-handle-color;

      &--arrow {
        span {
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 4px 0 4px;
          border-color: #000000 transparent transparent transparent;
          transform: rotateX(0deg);
          transition: all 300ms;
        }
      }
      &--arrow-top {
        span {
          transform: rotateX(150deg);;
        }
      }

    }
  }
  /* END Header */

  &__inner {
    margin-top: .8em;

    &--js-hidden {
      display: none;
    }

    &--scroll {
      height: 180px;
      overflow: auto;
      padding-right: 5px;
    }
  }

  &__checkgroup {

    &:not(:first-child) {
      padding-top: 3px;
    }

    &-control {
      display: table-cell;
      vertical-align: top;
      position: relative;
      top: -2px;
    }

    &-title {
      color: $chechbox-label-color;
      text-decoration: none;
      position: relative;
      z-index: 10;
    }

    &-count {
      display: block;
      position: absolute;
      color: $base-font-color-secondary;
      font-size: 0.9em;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      z-index: 10;
    }

  }

  &__checkboxinput:checked ~ &__checkboxlabel::after {
    background-color: $theme-light-color;
    background: linear-gradient(to top, darken($theme-light-color,10%), $theme-light-color);
    box-shadow: inset 0px 0 1px 1px #b4b4b4;
  }

  &__checkboxlabel {
    display: block;
    margin: 0 -10px;
    padding: 8px 25px;
    max-width: none;
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: inset 0px 1px 3px rgba(0,0,0,0.5);
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }
    &::before {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      background-color: $theme-light-color;
      left: 0;
      top: 0;
      z-index: 5;
      transition: all 500ms;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  &__range {

    &-field {
      padding-bottom: 15px;
    }

    &-control {
      @extend .form-control;
      @extend .input-sm;
    }

    &-slider {
      margin-bottom: 5px;
    }

  }

}