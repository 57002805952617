.partners {
  &__header {
    margin-bottom: 50px;
  }
  &__title {
    display: inline-block;
    font-size: 2.1rem;
    line-height: 1.1;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__item {
    padding-bottom: 30px;
    border-bottom: 1px solid $second-border-color;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  &__image {
    flex-shrink: 0;
    margin-right: 25px;
    font-size: 0;
    max-width: 70px;
  }
  &__info {
    font-size: 1rem;
  }
  &__name {
    color: #fff;
    font-weight: 700;
    margin-bottom: 8px;
  }
}