.product-gallery {
  padding: 20px;
  background: #fff;
  font-size: 0;

  &__thumbs {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -20px;
  }
  &__thumb {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    margin-top: 20px;
    width: 50%;

    @media (min-width: $screen-sm-min){
      width: 33.3%;
    }
    @media (min-width: $screen-md-min){
      width: 25%;
    }
    @media (min-width: $screen-lg-min){
      width: 20%;
    }
  }
  &__thumb-item {
    display: block;
    //width: 76px;
    //height: 76px;
    padding-bottom: 100%;
    background-size: cover;

  }

}
