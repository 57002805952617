[data-loader-frame]{
	position: relative;

	&:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		overflow: hidden;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 100;
	}

}