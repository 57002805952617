.breadcrumbs {

  $separator-ico: '/';
  $separator-color: #000;
  $vertical-gutter: 5px;

  @include clearfix;
  margin-top: (-$vertical-gutter);
  color: $base-font-color-secondary;
  font-size: 1.2rem;

  @media(min-width: $screen-md-min) {
    font-size: 0.9rem;
  }

  &__item {
    font-weight: 700;
    float: left;
    margin-top: $vertical-gutter;

    /* styles for separator */
    &:not(:last-child):after {
      content: $separator-ico;
      float: left;
      margin: 0 0.6em;
      color: $base-main-link-color;
    }
  }

  &__link {
    font-weight: 400;
    float: left;
    color: $base-main-link-color;
    text-decoration: none;
    transition: all 300ms;

    &:hover{
      color: $base-main-link-hover-color;
    }

  }

}