.cart-summary {
  $first-cell-width: 25px;

  &__items {
    font-size: 0.9rem;
  }

  &__row {
    margin-bottom:  15px;
    padding-bottom: 15px;
    border-bottom:  1px solid $base-border-color;
  }

  &__cell {
    @include clearfix;

    &:not(:last-child) {
      padding-bottom: 5px;
    }

    &--delete {
      float:       left;
      width:       $first-cell-width;
      margin-left: 0;
    }
    &--delete ~ & {
      margin-left: $first-cell-width;
    }

  }
  &__row:first-child &__cell {
    border-top:  0;
    padding-top: 0;
  }

  &__delete {
    margin-right: 10px;
  }
  &__delete-icon {
    display: block;
    width:   20px;
    height:  20px;
    color:   #C5CDD8;
    fill:    #C5CDD8;
  }

  &__product {
    padding-right: 30px;

    &--kit {
      &:not(:last-child):after {
        content:     '+';
        display:     block;
        font-size:   20px;
        color:       #000;
        margin-left: 25px;
      }
    }
  }

  &__quantity {
    width: 110px;
  }

  &__price {
  }

  &__subtotal {

    &-item {
      display:    table;
      width:      100%;
      border-top: 1px dashed $base-border-color;
      padding:    10px 0;

      &:first-child {
        border-top: 1px solid $base-border-color;
      }

    }

    &-title {
      display:        table-cell;
      vertical-align: middle;
    }

    &-value {
      display:        table-cell;
      vertical-align: middle;
      text-align:     right;
    }

  }

  &__total {

  }
  &__total-coupon {
    max-width:   400px;
    padding-top: 20px;
  }
  &__total-price {
    text-align:  right;
    margin-top:  10px;
    padding-top: 10px;
    border-top:  1px solid $base-border-color;

    &--order {
      border-top: 0;
      margin-top: 20px;
      padding:    20px;
      background: $product-price-bg-color;
    }
  }
  &__total-label {
    display:        inline-block;
    vertical-align: middle;
    padding-right:  15px;
  }
  &__total-price--order &__total-label {
    float:      left;
    margin-top: 8px;
    font-size:  1.3rem;
  }
  &__total-value {
    display:        inline-block;
    vertical-align: middle;
  }

  @media (min-width: $screen-sm-min) {
    &--in-modal &__items {
      display: table;
      width:   100%;
    }
    &--in-modal &__row {
      display: table-row;
    }
    &--in-modal &__cell {
      display:        table-cell;

      vertical-align: top;
      border-top:     1px solid $base-border-color;
      padding:        20px 0;
      &--delete {
        float: none;
      }
      &--delete ~ & {
        margin-right: 0;
      }
    }
    &--in-modal &__price {
      width:      80px;
      text-align: right;
      float:      right;
    }
  }

  @media (min-width: $screen-md-min) {
    &__items {
      display: table;
      width:   100%;
    }
    &__row {
      display: table-row;
    }
    &__cell {
      display:        table-cell;
      vertical-align: top;
      border-top:     1px solid $base-border-color;
      padding:        20px 0;
      &--delete {
        float: none;
      }
      &--delete ~ & {
        margin-right: 0;
      }
    }
    &__price {
      width:      80px;
      text-align: right;
      float:      right;
    }
  }
  @media (min-width: $screen-lg-min) {
  }

}