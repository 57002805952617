.btn {
  text-decoration: none;
  transition: all 500ms;

  &--main {
    /* cancel default bootstrap blue outline */
    outline: 0 !important;
    /* cancel default bootstrap border */
    border: 0;

    background: $theme-main-color;
    color: $btn-main-color;

    &:hover,
    &:focus {
      background: $btn-main-bg-hover;
      color: $btn-main-color-hover;
    }
  }

  &--va-m {
    & > * {
      display: inline-block;
      vertical-align: middle;

      &:not(:first-child) {
        margin-left: 5px;
      }
    }
  }

}

.btn-default{
  transition: all 500ms;
  background: #565e5c;
  /* cancel default bootstrap blue outline */
  outline: 0 !important;
  color: #fff !important;
  border-radius: 0 !important;

  &:hover,
  &:focus,
  &:active{
    background: darken(#565e5c,8%) !important;
    border-color: darken(#565e5c,8%) !important;
  }

  &__ico{
    display: inline-block;
    fill: $theme-main-color;
    color: $theme-main-color;

    &--search{
      width: 13px;
      height: 13px;
      position: relative;
      top: 2px;
    }
    &--download {
      width: 15px;
      height: 15px;
      fill: #000;
    }
    &--down,
    &--top {
      width: 8px;
      height: 8px;
      fill: #fff;
      color: #fff;
    }
  }
}

.btn-primary{
  transition: all 500ms;

  /* cancel default bootstrap blue outline */
  outline: 0 !important;
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;
  border-radius: $btn-primary-border-radius;
  color: $theme-main-color;
  text-decoration: none;

  &:hover{
    background-color: $btn-primary-bg-hover !important;
    border-color: $btn-primary-border-hover !important;
    color: $theme-main-color;
    text-decoration: none;
  }
  &:focus, &:active {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
    border-radius: $btn-primary-border-radius;
    color: $theme-main-color;
  }
  &:active:focus {
    color: $theme-main-color;
  }
  &--lg {
    padding: 13px 20px;
    font-size: 1.2rem;
    @media (min-width: $screen-sm-min) {
      padding: 13px 35px;
    }
    @media (min-width: $screen-lg-min) {
      padding: 13px 50px;
    }
  }
}

.btn-order {
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;
  }

}

.btn-white{
  @extend .btn;
  @extend .btn-default;
  background: #fff;

  &--active{
    background: #f2f6f9;
  }

  &__ico{
    display: inline-block;
    fill: $theme-third-color;
    color: $theme-third-color;
    position: relative;
    top: 3px;

    &--wishlist {
      width: 16px;
      height: 15px;
    }

    &--compare {
      width: 13px;
      height: 15px;
    }
  }
  &--active &__ico{
    fill: #000;
    color: #000;
  }

}

.btn-light{
  &,
  &:hover,
  &:active,
  &:focus{
    color: #ba9659;
    background: #fff8dd !important;
    border: 1px solid #eedbb2 !important;
  }
}

.btn-mobile-icon {
  display: block;
  width: 22px;
  height: 19px;
  position: relative;
  line-height: 19px;
  font-size: 0;
  &__lines:nth-child(2) {
    display: block;
    height: 3px;
    background-color: $btn-humburger-color;
    width: 100%;
    opacity: 1;
    transition: all 300ms;
    top: 50%;
    left: 0;
  }
  &__lines:first-child {
    display: block;
    position: absolute;
    content: "";
    background-color: $btn-humburger-color;
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    transition: all 500ms;
  }
  &__lines:last-child {
    display: block;
    position: absolute;
    content: "";
    background-color: $btn-humburger-color;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    transition: all 500ms;
  }
  &--active &__lines:first-child {
    transform: rotate(45deg);
    top: 8px;
  }
  &--active &__lines:nth-child(2) {
    opacity: 0;
  }
  &--active &__lines:last-child {
    transform: rotate(-45deg);
    bottom: 8px;
  }

}

.btn-search {
  padding: 10px;
  position: relative;
  background-color: $theme-third-color;
  &::after, &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    transition: transform 300ms;
  }
  &::after {
    border-top: 1px solid $theme-light-color;
    border-bottom: 1px solid $theme-light-color;
    transform: scale(0,1);

  }
  &::before {
    border-left: 1px solid $theme-light-color;
    border-right: 1px solid $theme-light-color;
    transform: scale(1,0);
  }
  &__wrapper {
    display: inline-block;
    vertical-align: top;
    width: 14px;
    height: 14px;
    position: relative;
  }
  &__icon {
    top: 0;
    left: 0;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid $base-font-color;
    transition: all 300ms;
    &::after {
      position: absolute;
      width: 6px;
      height: 3px;
      background-color: $base-font-color;
      content: '';
      border-radius: 10px;
      bottom: -2px;
      right: -4px;
      transform: rotate(45deg);
      transition: all 300ms;
    }
  }
  &:hover {

    &::after, &::before {
      transform: scale(1);
    }

  }

  &:hover &__icon {
    border-color: $theme-light-color;
    &::after {
      background-color: $theme-light-color;
    }
  }
}

.button {

  &--loader {
    display: inline-block;
    height: 20px;
    width: 20px;
    fill: #fff;
    animation: rotateIcon 1s linear infinite;
  }
}

@keyframes rotateIcon {
  to {
    transform: rotate(360deg);
  }
}

