.widget-secondary{
	
	&__header{
		margin-bottom: 2.8rem;
		font-size: 0;
		color: $widget-secondary-title-color;
	}

	&__title{
		display: inline-block;
		vertical-align: baseline;
		@include fluidFontSize(16px, 35px, 480px, 1280px, 26px);
		line-height: 1.1;
		text-transform: uppercase;
		font-weight: 700;
		&-small {
			font-size: 1rem;
			font-weight: 500;
			margin-bottom: 8px;
		}
		position: relative;
		padding-right: 30px;
		margin-right: 30px;
		&::after {
			position: absolute;
			content: '';
			width: 1px;
			height: 100%;
			background-color: $second-border-color;
			top: 0;
			right: 0;
		}
	}

	&__viewall{
		display: inline-block;
		vertical-align: baseline;
		font-size: 1rem;
	}

	&__hlink{
		@include link-main;
		position: relative;
		padding-right: 17px;
		&::after {
			content: '';
			position: absolute;
			width: 1px;
			height: 4px;
			background-color: $base-main-link-color;
			transform: rotate(-45deg);
			right: 0;
			top: 5px;
			transition: all 300ms;
		}
		&::before {
			content: '';
			position: absolute;
			width: 1px;
			height: 4px;
			background-color: $base-main-link-color;
			transform: rotate(45deg);
			right: 0;
			bottom: 4px;
			transition: all 300ms;
		}
		&:hover {
			&::after, &::before {
				background-color: $base-main-link-hover-color;
			}
		}
	}

}