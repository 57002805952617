.seo-text{
	
	&__title{
		font-size: 24px;
		margin-bottom: 20px;
		color: $seo-text-title-color;
	}

	&__desc{

	}

}