/*
--------------------------------------------------
Typography styles.
Use for content outputted from admin panel
WYSIWYG editor
--------------------------------------------------
*/

.typo{
  line-height: 1.5;

  &--sm-line-height {
    line-height: 1.2;
  }

  > :last-child{
    margin-bottom: 0;
  }

  > :first-child{
    margin-top: 0;
  }


  a{
    @include link-main;
  }

  ul,
  ol,
  p,
  dl,
  table,
  figure,
  form,
  form fieldset
  {
    margin: 0 0 0.6em 0;
  }

  figcaption{
    font-size: 0.8em;
    line-height: 1;
    text-align: center;
  }

  blockquote{
    position: relative;
    min-height: 45px;
    padding: 0 0 0.8em 3.7em;
    color: #999;
  }
  blockquote:after{
    content: "";
    display: table;
    clear: both;
  }
  blockquote:before{  
    content: '\201C';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10rem;
    line-height: .8;
  }

  hr{
    height: 1px;
    background: $base-font-color;
  }

  /** Headers BEGIN **/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin: 1.5em 0 0.5em;
    line-height: 1.1;
  }
  h1{
    font-size: 2em;
    color: #000;
  }

  h2{
    font-size: 1.6em;
  }
  h3{
    font-size: 1.4em;
  }
  h4{
    font-size: 1.3em;
  }
  h5{
    font-size: 1.2em;
  }
  h6{
    font-size: 1.1em;
  }
  /** END Headers **/



  /** List BEGIN **/
  ul{
    margin-left: 1.1em;
    list-style: disc;
  }
  ul ul{
    margin: 0 0 0 2em;
    list-style: circle;
  }
  ul ul ul{
    list-style: square;
  }

  ol{
    list-style: none;
    counter-reset: num;
  }
  ol ol{
    margin: 0 0 0 2em;
  }
  ol li:before{
    content: counters(num,'.') ' ';
    counter-increment: num;
  }

  dt{
    font-weight: bold;
  }
  dd{
    margin-bottom: 0.4em;
  }
  /** END List **/



  /** Table BEGIN **/
  table{
    border-color: black;
  }
  table caption{
    font-size: 1.3em;
  }
  table thead{
    font-weight: bold;
  }
  table tfoot{
    font-weight: bold;
  }
  table tbody{
  }
  table tr{
  }
  table td{
    padding: 0.2em 0.5em 0.2em 0.5em ;
  }
  /** END Table **/


  /** Inline BEGIN **/
  strong,
  b{
    font-weight: bold;
  }

  em,
  cite,
  dfn,
  i,
  var
  {
    font-style: italic;
  }
  i.fa{
    /* Font Awesome default reset */
    font-style: normal !important;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, serif;
    font-size: 1em;
  }

  ins{
    text-decoration: underline;
  }

  del,
  s,
  strike{
    text-decoration: line-through;
  }

  mark{
    background: #ff0;
    color: #000;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup{
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup{
    top: -0.5em;
  }

  sub{
    bottom: -0.25em;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  iframe{
    max-width:100%;    
    
    /* youtube video height decrease on small devices */
    @media(max-width: $screen-xs-max){
      height:auto;
    }
  }
}