.catalog-section {
  $photo-size: 200px;
  margin: -15px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  & > * {
    margin: 15px;
  }

  &__item {
    text-decoration: none;
    color: $base-second-link-color;
    background-color: #fff;
    width: 100%;
    display: block;
    border: 1px solid $third-border-color;
    position: relative;
    @media (min-width: $screen-xs-min){
      width: calc(50% - 30px);
    }
    @media (min-width: $screen-md-min){
      width: calc(33.333% - 30px);
    }
    @media (min-width: $screen-lg-min){
      width: calc(25% - 30px);
    }
    @media (min-width: $site-max-width){
      width: calc(20% - 30px);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 3px solid $theme-light-color;
      border-bottom: 3px solid $theme-light-color;
      transform: scale(0, 1);
      transition: all 500ms;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-left: 3px solid $theme-light-color;
      border-right: 3px solid $theme-light-color;
      transform: scale(1, 0);
      transition: all 500ms;
    }
    &:hover {
      &::after, &::before{
        transform: scale(1);
        z-index: 10;
      }
    }
  }


  &__image {
    position: relative;
    &::after {
      padding-bottom: 100%;
      content: '';
      display: block;
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-height: 100%;
  }

  &__caption {
    min-height: 75px;
    padding: 5px 20px 10px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
  }

}