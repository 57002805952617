.product-intro {

  &__pics {
    margin-bottom: 25px;
  }
  /* Additional information like brand, number */
  &__addition {
    @include clearfix;
    padding: 5px 0 5px 0;
    font-size: 0.9rem;
    color: $base-font-color-secondary;

    &-item {
      float: left;
      margin-right: 30px;
      padding-bottom: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      @include link-second;
    }
  }

  /* Product variants */
  &__variants {
    max-width: 300px;
  }
  &__section {
    margin-bottom: 25px;
    &-title {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  }
  &__file {
    padding: 10px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $second-border-color;
    }
    &-link {
      display: inline-block;
      vertical-align: top;
      color: $base-font-color-secondary;
      text-decoration: none;
      font-size: 0;
      transition: all 500ms;
      &:hover {
        color: $theme-light-color;
      }
    }
    &-icon {
      display: inline-block;
      vertical-align: middle;
      width: 31px;
      height: 31px;
      margin-right: 20px;
    }
    &-title {
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
    }
  }
  &__accessories {
    &-item {
      &:not(:last-child) {
        margin-bottom: rem(8);
      }
    }
    &-link {
      display: inline-block;
      vertical-align: top;
      color: $base-font-color-secondary;
      text-decoration: none;
      font-size: 1rem;
      transition: all 500ms;
      &:hover {
        color: $theme-light-color;
      }
    }
  }

  /*  Short Description and main params */
  &__short-desc {
    margin-bottom: 25px;
  }

  /* Product like and share buttons */
  &__social {
    margin-top: 15px;
    font-size: 0.9rem;
    padding-top: 15px;
    border-top: 1px dashed $base-border-color;

    &-row {
      display: table;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &-title {
      display: table-cell;
      vertical-align: middle;
      padding-right: 15px;
    }

    &-inner {
      display: table-cell;
      vertical-align: middle;
    }

  }

  /*  Product is archived */
  &__archive {
    margin: 10px 0;
    display: inline-block;
    padding: 10px 30px;
    background: $theme-secondary-color;
    color: #000;
    font-weight: bold;
    font-size: 1.4rem;
  }

  &__colors {
    margin-bottom: 5px;
  }

}