.modal {
  position: relative;
  margin: 0 auto;
  width: 90%;
  max-width: $modal-size;
  background: #fff;

  /* Modal elements */
  &__header {
    position: relative;
    padding: 15px $modal-hor-padding;
    background: $modal-bg;
    border-bottom: 1px solid $modal-border;
  }
  &__header-title {
    font-size: 21px;
    line-height: 1;
    color: $modal-color;
    padding-right: 20px;
  }

  &__header-close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 62px;
    cursor: pointer;
    border-left: 1px solid $modal-border;
    
    &:active {
      box-shadow: inset 0 5px 7px rgba(#000, 0.125);
    }
  }
  &__header-close-ico{
    position: absolute;
    top:    0;
    right:  0;
    bottom: 0;
    left:   0;
    margin: auto;
    width: 16px;
    height: 16px;
    fill: $base-font-color-secondary;
  }

  &__content {
    padding: 15px $modal-hor-padding;
    background-color: $theme-secondary-color;
    color: $base-font-color;

    &-cell {
      margin-bottom: 15px;
    }
  }

  &__footer {
    text-align: right;
    padding: 15px $modal-hor-padding;
    background: $modal-bg;
    border-top: 1px solid $modal-border;

    &-row {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &-btn {
      display: inline-block;
      margin-left: 15px;
    }
  }

  /* Modal modifiers */
  &--sm {
    max-width: $modal-size-sm;
  }

  &--lg {
    max-width: $modal-size-lg;
  }

}