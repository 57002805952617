.product-photo {

  &__item {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: $product-photo-fluid-height;
    max-height: 100%;
    text-align: center;

    &--no-photo {
      @include link-empty;
    }

    &--xs,
    &--sm,
    &--md,
    &--lg {
      padding-bottom: 0px;
    }

    &--xs {

      width: $product-photo-fix-height-xs;
      height: $product-photo-fix-height-xs;
      max-height: $product-photo-fix-height-xs;

      .product-photo__img {
        padding: 5px;
      }
    }
    &--sm{
      height: $product-photo-fix-height-sm;
      max-height: $product-photo-fix-height-sm;
    }

    &--md{
      height: $product-photo-fix-height-md;
      max-height: $product-photo-fix-height-md;
    }

    &--lg {
      background-color: #fff;
      height: $product-photo-fix-height-lg;
      max-height: $product-photo-fix-height-lg;
    }

  }

  &__img {
    /* use important to override slick slide img display:block rule */
    display    : inline-block !important;
    @include absolute-center-translate;
    padding    : 10px;
    max-height : inherit;
  }

  &__labels {
    position : absolute;
    top      : 8px;
    left    : 8px;
    z-index: 11;
  }

  &__label {
    text-align: center;
    min-width: 38px;
    padding: 13px 5px;
    line-height: 1;
    font-size   : 0;
    font-weight: 700;
    color       : #FFF;
    white-space : nowrap;
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top : 5px;
    }

    &--hit {
      background-color : $product-label-bg-hit;
    }
    &--hot {
      background-color : $product-label-bg-hot;
    }
    &--action {
      background-color : $product-label-bg-action;
    }
    &--discount {
      background-color : $product-label-bg-discount;
    }
  }

  &__label-text {
      font-size: 12px;
      line-height: 12px;
  }
  &__thumbs {
    position: relative;
    padding: 0 45px;
  }
  &__thumb-arrow {
    width: 20px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    fill: $base-font-color-secondary;
    color: $base-font-color-secondary;
    transition: all 500ms;
    &:hover {
      fill: $theme-light-color;
      color: $theme-light-color;
    }
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  &__thumb-arrow.slick-disabled {
    opacity: 0.35;
    cursor: default;
    &:hover {
      fill: $base-font-color-secondary;
      color: $base-font-color-secondary;
    }
  }

  &__thumbs-inner {
    font-size   : 0;
    margin-top  : 5px;
    margin-left : -10px;
  }

  &__thumb {
    display        : inline-block;
    vertical-align : top;
    padding-left   : 10px;
    padding-top    : 10px;
    width          : percentage(1/$product-photo-thumbs-number);

    &-item {
      position : relative;
      border   : 1px solid $product-photo-border-color;
      display  : block;
      height   : $product-photo-thumb-height;
      opacity  : .5;
      background-color: #fff;


      &[data-product-photo-thumb-active] {
        opacity : 1;
      }
    }

    &-img {
      @include absolute-center-translate;
      display    : block;
      padding    : 5px;
      max-height : $product-photo-thumb-height;
    }
  }

  &__wrapper {
    position : relative;
  }

  &__zoom {
    position: relative;
    top: 0px;
    left: 100%;
    z-index: 1000;

    display: block;
    width: $product-photo-zoom-width;
    height: $product-photo-zoom-height;
    overflow: hidden;

    background: #FFF;
    border: 1px solid #C7C7C7;

    &:hover {
      display: none;
    }
  }
  &__zoom-icon-box {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 26px;
    height: 26px;
    background-color: rgba(#000000,0.5);
    line-height: 26px;
    font-size: 0;
  }
  &__zoom-icon-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    position: relative;
  }
  &__zoom-icon {
    top: 0;
    left: 0;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    transition: all 300ms;
    &::after {
      position: absolute;
      width: 6px;
      height: 3px;
      background-color: #fff;
      content: '';
      border-radius: 10px;
      bottom: -2px;
      right: -4px;
      transform: rotate(45deg);
      transition: all 300ms;
    }
  }



}