.footer {
  font-size: 0;
  color: $footer-text-color;
  @media (min-width: $screen-md-min) {
    white-space: nowrap;
  }

  &__col {
    padding: 15px 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    font-size: 1rem;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      padding: 30px 0;
      width: 65%;
      padding-left: 15px;
      padding-right: 0;
    }
    @media (min-width: $screen-md-min) {
      padding-left: 15px;
      padding-right: 15px;
      width: 50%;
    }
    @media (min-width: $screen-lg-min) {
      padding-left: 50px;
      padding-right: 50px;
    }
    &:first-child {
      width: 100%;
      @media (min-width: $screen-sm-min) {
        width: 35%;
        padding-left: 0;
        padding-right: 15px;
      }
      @media (min-width: $screen-md-min) {
        padding-left: 0;
        padding-right: 15px;
        width: 25%;
      }
      @media (min-width: $screen-lg-min) {
        padding-right: 50px;
      }
    }
    &:nth-child(2) {
      @media (min-width: $screen-md-min) {
        position: relative;
        &::before {
          position: absolute;
          content: '';
          width: 1px;
          height: 90%;
          right: 100%;
          bottom: 0;
          background-color: $second-border-color;
        }
      }
      @media (min-width: $screen-md-min) {
        &::before {
          height: 80%;
        }

        &::after {
          position: absolute;
          content: '';
          width: 1px;
          height: 80%;
          left: 100%;
          bottom: 0;
          background-color: $second-border-color;
        }

      }
    }
    &:last-child {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      @media (min-width: $screen-md-min) {
        padding-right: 0;
        padding-left: 15px;
        width: 25%;
      }
      @media (min-width: $screen-lg-min) {
        padding-left: 50px;
      }

    }
  }
  &__title {
    font-size: 1.2rem;
    color: $footer-title-color;
  }

  &__inner {
    margin-top: 10px;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    &-item {
      width: 50%;
      margin-bottom: 5px;
      &:nth-child(2n) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        .footer__menu-wrapper {
          max-width: 150px;
          width: 100%;
        }
      }
      &--active {

        .footer__menu-link {
          cursor: default;
          pointer-events: none;
          color: $base-font-color;
        }
      }
    }
    &-link {
      text-decoration: none;
      color: $theme-light-color;
      transition: all 300ms;
      &:hover {
        color: $base-font-color-secondary;
      }
    }
  }

  &__center {

    &-wrapper {
      color: $base-font-color-secondary;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: -15px;
      flex-wrap: wrap;
      @media (min-width: $screen-sm-min) {
        flex-wrap: nowrap;
      }
    }
  }

  &__items {
    max-width: 200px;
    line-height: 1.7;
  }

  &__item {
    &--nowrap {
      white-space: nowrap;
    }

  }
  &__link {
    text-decoration: none;
    color: $theme-light-color;
    transition: all 300ms;
    &:hover {
      color: $base-font-color-secondary;
    }
  }
  &__copyright {
    margin-bottom: 25px;
  }
  &__destributor-imagebox {
    width: 100px;
    @media (min-width: $screen-lg-min){
      width: 160px;
    }

  }


  &__center-row {
    color: #9d9d9d;
    display: flex;
    align-items: flex-start;

    @media (min-width: $screen-sm-min){
      align-items: center;
    }


  }
  &__center-col-image {
    margin-right: 20px;
    @media (min-width: $screen-sm-min){
      margin-right: 50px;
      //margin-top: 30px;
    }
  }
  &__center-col-body {
    @media (min-width: $screen-sm-min){
      width: 400px;
    }
  }


  &__destributor-text {
    margin-bottom: 20px;
  }
}