.articels {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  flex-wrap: wrap;

  &__imagebox {

    &-wrap {
      width: 100%;
      @media (min-width: $screen-sm-min) {
        width: 50%;
      }
      @media (min-width: $screen-lg-min) {
        width: 30%;
      }
    }

    position: relative;
    overflow: hidden;

    &-image {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      z-index: 5;
      opacity: 0.5;
      transform: scale(1);
      transition: opacity 1s, transform 3s;
    }

    &-header {
      color: #fff;
      position: absolute;
      z-index: 10;
      top: 10%;
      right: 10%;
      padding-bottom: 10px;
      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 40%;
        height: 1px;
        background-color: #fff;
        z-index: 5;
      }
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $theme-light-color;
        transition: all 1s;
        z-index: 6;
      }
    }
    &-title {
      text-transform: uppercase;
      font-weight: 700;
      max-width: 305px;
      @include fluidFontSize(18px, 30px, 480px, 1280px, 24px);
    }
    &-subtitle {
      text-transform: uppercase;
      font-size: 1rem;
    }
    &:hover {
      .articels__imagebox-image {
        opacity: 0.75;
        transform: scale(1.1);
      }
      .articels__imagebox-header {
        &::before {
          width: 40%;
        }
      }
    }
  }
  &__item {
    @media (min-width: $screen-md-min) {
      width: 20%;
    }
    @media (max-width: 1530px) {
      width: 23.2%;
    }
    @media (max-width: $screen-lg-min) {
      width: 50%;
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
    text-decoration: none;
    padding: 20px;
    padding-top: 27px;
    position: relative;
    transition: all 500ms;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::after {
      position: absolute;
      content: '';
      width: 4px;
      height: 27px;
      background-color: $base-font-color-secondary;
      top: 0;
      right: 38px;
      z-index: 5;
    }

    @media (max-width: $screen-lg-min) {
      @media (min-width: $screen-sm-min) {
        &::after, &::before {
          display: none;
        }
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 4px;
      height: 0px;
      background-color: $theme-third-color;
      top: 0;
      right: 38px;
      transition: all 500ms;
      z-index: 6;
    }
    &-header {
      padding: 20px 0;
    }
    &-title {
      text-transform: uppercase;
      color: #fff;
      @include fluidFontSize(18px, 24px, 480px, 1280px, 20px);
      transition: all 500ms;
      margin-bottom: 20px;
      padding-right: 25px;
    }
    &-desc {
      padding: 20px 0;
      transition: all 500ms;
      color: $base-font-color-secondary;
      font-size: 1.2rem;
    }
    &-readmore {
      padding-right: 17px;
      position: relative;
      white-space: nowrap;
      opacity: 0;
      color: $theme-third-color;
      transition: all 300ms;

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 4px;
        background-color: $theme-third-color;
        transform: rotate(45deg);
        right: 0;
        bottom: 4px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 4px;
        background-color: $theme-third-color;
        transform: rotate(-45deg);
        right: 0;
        top: 6px;
      }
    }

    &:hover {
      background-color: $theme-light-color;
      &::before {
        height: 27px;
      }
      .articels__item-title {
        color: $theme-third-color;
      }
      .articels__item-desc {
        color: $theme-third-color;
      }
      .articels__item-readmore {
        opacity: 1;
      }
    }
  }
}
