.small-post {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  &__header {
    margin-bottom: 10px;
  }
  &__footer {
    width: 100%;
  }
  &__image {
    display: block;
    font-size: 0;
    width: 54.35%;
    position: relative;
    overflow: hidden;
    &::after {
      display: block;
      content: '';
      padding-bottom: 112%;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      transform: scale(1);
      transition: all 3s;
    }
    &:hover img {

        transform: scale(1.1);

    }
  }
  &__inner {
    padding: 20px 15px 10px 15px;
    position: relative;
    background-color: $theme-third-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      width: 45.655%;
      &--full {
        width: 100%;
      }
    }

    @media (min-width: $screen-sm-min) {
      &--arrow {
        &::after {
          position: absolute;
          content: '';
          left: 100%;
          top: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 12px 0 12px 12px;
          border-color: transparent transparent transparent $theme-third-color;
          z-index: 5;
        }
      }
    }
  }

  &__date {
    text-transform: uppercase;
    display: block;
    color: $base-font-color-secondary;
    margin-bottom: 10px;
  }

  &__title {
    text-transform: uppercase;
    word-wrap: break-word;
    @include fluidFontSize(16px, 21px, 480px, 1280px, 18px);

    &-link {
      @include link-main;
      color: #fff;
    }
  }

  &__desc {
    margin-bottom: 15px;
  }
  &__basement {
    border-top: 1px solid $base-font-color-secondary;
    padding-top: 10px;
  }

  &__readmore {
    @include link-main;
    display: inline-block;
    white-space: nowrap;
    padding-right: 17px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 4px;
      background-color: $base-font-color-secondary;
      transform: rotate(45deg);
      right: 0;
      bottom: 5px;
      transition: all 300ms;
    }
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 4px;
      background-color: $base-font-color-secondary;
      transform: rotate(-45deg);
      right: 0;
      top: 6px;
      transition: all 300ms;
    }
    &:hover {
      &::after, &::before {
        background-color: $theme-light-color;
      }
    }

  }

}