.discount-info {

  $next-discount-color: #E89B88;

  font-size: 1rem;

  &__list {
    display: table;
    width: 100%;
  }

  &__row {
    display: table-row;
  }

  &__col {
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px dashed $base-border-color;
    padding: 5px;

    &--title {
      position: relative;
    }

    &--value {
      text-align: right;
    }
  }

  &__note {
    font-size: 0.86em;
    color: $next-discount-color;
    // font-style: italic;`
  }

  &__message {
    margin-top: 20px;
  }

}