.widget-primary {
  $title-color:   #fff;
  $arrow-color:   #fff;
  $title-indent:  13px;
  $arrow-height:  82px;

  text-align: center;
  position: relative;

  &__header {
    padding: 30px 0;
    background-color: $theme-third-color;
    text-transform: uppercase;
    color: $title-color;
    &-text {
      display: block;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  &__title {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;

    @include fluidFontSize(16px, 35px, 480px, 1280px, 28px);
  }

  &__inner{
    position: relative;
  }

  &__arrows {

  }

  &__arrow {
    @include position(absolute, calc(50% - #{$arrow-height/2}) null null null);
    display: inline-block;
    cursor: pointer;
    padding: 0px 15px;
    fill: $arrow-color;
    color: $arrow-color;
    background: rgba($theme-main-color,0.5);
    transition: all 500ms;
    &:hover {
      background: rgba($theme-main-color,0.8);
    }

    svg {
      width: 15px;
      height: $arrow-height;
    }

    /* styles for disabled arrow, when slick carusel infinite is disabled */
    &.slick-disabled {
      visibility: hidden;
      cursor: default;
    }

    &--left{
      left: 0px;
    }

    &--right{
      right: 0px;
    }
  }

}