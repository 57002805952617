.properties {
  width: 100%;
  font-size: 1.2rem;
  thead {
    background-color: $theme-secondary-color;
    color: $theme-main-color;
    text-align: left;
  }
  th {
    padding: 15px 30px;
    border-bottom: 4px solid $theme-main-color;
    &:not(:last-child) {
      border-right: 1px solid $theme-main-color;
    }
  }
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid $theme-main-color;
    }
  }
  td {
    background-color: $theme-third-color;
    padding: 15px 30px;
    &:not(:last-child) {
      border-right: 1px solid $theme-main-color;
    }
  }

}