/*
--------------------------------------------------
Catalog sidebar menu
--------------------------------------------------
*/
.sidebar-nav {
  padding: 10px 0;
  font-size: 0.9rem;
  background-color: $theme-third-color;

  //Elements
  &__item {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $theme-light-color;
      transition: all 500ms;
    }

    //Element Modifiers
    &--active {
      .sidebar-nav__link {
        @include link-empty;
      }
    }

  }
  &__title {
    color: $base-font-color-secondary;
    text-transform: uppercase;
    padding: 10px;
  }

  &__link {
    position: relative;
    z-index: 10;
    display: block;
    padding: 10px;
    padding-right: 30px;
    font-weight: 700;
    color: $base-white-link-color;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 300ms;
    &-quantity {
      position: absolute;
      color: #fff;
      top: 0;
      right: 10px;
      transform: translateY(50%);
      z-index: 10;
    }
  }
  &__item:hover {
    &::after {
      width: 100%;
    }
  }
  &__item:hover &__link {
    color: $theme-third-color;
  }

}