.page {

  /* Mobile sidebar slide panel */
  &__mobile {
    position: fixed;
    width: $page-mobile-width;
    height: 100%;
    top: 0;
    left: -$page-mobile-width;
    z-index: $page-ovarlay-z-index + 1;
    background: $page-mobile-bg;
    color: $page-mobile-color;
    overflow-y: auto;
    overflow-x: hidden;
    transition: transform $page-mobile-speed;

    /* enables momentum scrolling in iOS overflow elements */
    -webkit-overflow-scrolling: touch;

    &--js-open{
      transform: translateX($page-mobile-width);
    }

  }

  /* Page overlay visible if mobile panes is open */
  &__overlay {
    display: block;
    @include position(fixed, 0 0 0 0);
    z-index: $page-ovarlay-z-index;
    background-color: $page-overlay-color;
    animation: fade 500ms;

    @keyframes fade {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
  }

  /*
      * Main site content frame
      * Shifting to right when mobile menu is siliding.
      * Adding via javascript by clicking on mobile menu btn
  */
  &__body {
    @include transition(transform $page-mobile-speed);
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &--js-pushed {
      @include transform(translateX($page-mobile-width));
      /* Disable scrolling while mobile bar is opened */
      height: 100vh;
      overflow: hidden;
    }
  }

  &__mainframe {
    @extend .container-fluid;
    max-width: $site-max-width;
  }

  /* Main container inside wrapper for sticky footer */
  &__wrapper {
    flex-grow: 1;
  }

  /* Container for static width frames */
  &__container {
    //extending bootstrap container
    @extend .container-fluid;
    max-width: $site-max-width;
  }

  /* Header line and main header wrapper */
  &__hgroup {
  }

  /* Main header */
  &__header {
    background-color: $theme-main-color;
  }

  /* Main banner wrapper */
  &__main-banner {
    &--mobile {
      .banner-simple__item {
        overflow: hidden;
        &::after {
          content: '';
          padding-bottom: 80%;
          display: block;
        }
      }
      .banner-simple__image {
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        max-height: 100%;
        transform: translateX(-50%);
        max-width: none;
        width: auto;
      }
    }
  }

  &__user-bar{
    float: right;
  }

  /* Page content wrapper */
  &__content {
    margin-bottom: 40px;
    &--no-margin {
      margin-bottom: 0px;
    }
  }

  /* Main horisontal navigation wrapper */
  &__mainnav-hor {
    position: relative;
    border-bottom: 2px solid $theme-main-color;
    border-top: 2px solid $theme-main-color;
    background-color: $theme-third-color;
  }

  /* Breadcrumbs wrapper */
  &__breadcrumbs {
    padding: 10px 0;
    background-color: $theme-third-color;
  }

  /* Site benefits wrapper */
  &__seo-text {
    padding: 40px 0 40px 0;
    border-bottom: 1px solid $footer-border-color;
  }

  /* Page viewed wrapper */
  &__viewed {
    padding-bottom: 50px;
  }

  /* Footer group */
  &__fgroup {
    position: relative;
    background-color: $theme-third-color;
  }

  /* Main footer wrapper */
  &__footer {
  }

  /* Footer line in the bottom */
  &__basement {
    padding-bottom: 20px;
  }
}