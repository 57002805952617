.product-fullinfo{

	&__item{
		&:not(:last-child){
			margin-bottom: 40px;
		}
	}

	&__header{
		margin-bottom: 25px;
		&--center {
			text-align: center;
		}
	}
	&__subtitle {
		font-size: 1rem;
		text-transform: uppercase;
		color: #fff;
	}
	&__title{
		color: #fff;
		text-transform: uppercase;
		font-size: 1.5rem;
		&--lg {
			font-size: 2.5rem;
		}
	}
	&__inner {
		position: relative;
		&--scroll {
			overflow: auto;
		}
	}
	&__inner-arrow {
		width: 20px;
		height: 40px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		fill: $base-font-color-secondary;
		color: $base-font-color-secondary;
		transition: all 500ms;
		&:hover {
			fill: $theme-light-color;
			color: $theme-light-color;
		}
		&--left {
			left: 0;
		}
		&--right {
			right: 0;
		}
	}
	&__inner-arrow.slick-disabled {
		opacity: 0.35;
		cursor: default;
		&:hover {
			fill: $base-font-color-secondary;
			color: $base-font-color-secondary;
		}
	}
	&__table {
		overflow-x: auto;
		table {
			min-width: 800px;
			width: 100%;
			font-size: 1.2rem;
			table-layout: auto;
			tr {
				&:first-child {
					color: $theme-main-color;
					text-align: left;
					td {
						background-color: $theme-secondary-color;
						border-bottom: 4px solid $theme-main-color;
					}
				}
				&:not(:last-child) {
					border-bottom: 1px solid $theme-main-color;
				}
			}
			td {
				background-color: $theme-third-color;
				padding: 6px 30px;
				&:not(:last-child) {
					border-right: 1px solid $theme-main-color;
				}
			}
		}
	}


}