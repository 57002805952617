.gallery-image {
  $hover-bg-color: rgba(30, 39, 47, 0.5);
  $hover-bg-text-color: #FFF;

  &__link {
    display: block;
    position: relative;

    &:hover {
      .gallery-image__zoom {
        display: block;
      }
    }
  }

  &__img {
    display: block;
  }

  &__zoom {
    display: none;
    @include position(absolute, 0 null null 0);
    width: 100%;
    height: 100%;
    background-color: $hover-bg-color;
    color: $hover-bg-text-color;

    &-outer {
      display: table;
      height: 100%;
      width: 100%;
    }

    &-inner {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    &-ico {
      display: block;
      margin: 0 auto;
      width: 24px;
      height: 24px;
    }
  }

  &__desc {
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
}