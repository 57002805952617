.certificate {

  text-decoration: none;
  height: 100%;
  background-color: #fff;
  position: relative;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  &__info {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  &__photo {
    display: block;
    font-size: 0;
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 80%;
    position: relative;
    img {
      position: absolute;
      height: 100%;
      max-height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__title {
    margin-bottom: 15px;

    &-link {
      color: $base-second-link-color;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.5rem;
      transition: all 500ms;
      &:hover {
        color: $theme-light-color;
      }
    }
  }
  &__shortdesc {
    margin-bottom: 15px;
    color: $base-font-color;
  }
  &__more-box {
    padding-top: 15px;
    padding-bottom: 5px;
    border-top: 1px solid $third-border-color;
  }
  &__more {
    color: $base-font-color-secondary;
    display: inline-block;
    vertical-align: top;
    padding-right: 13px;
    position: relative;
    text-decoration: none;
    transition: all 500ms;
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 4px;
      background-color: $base-font-color-secondary;
      transform: rotate(45deg);
      right: 0;
      bottom: 5px;
      transition: all 500ms;
    }
    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 4px;
      background-color: $base-font-color-secondary;
      transform: rotate(-45deg);
      right: 0;
      top: 6px;
      transition: all 500ms;
    }
    &:hover {
      color: $theme-light-color;
      &::before, &::after {
        background-color: $theme-light-color;
      }
    }
  }

}