.pictograms {
  font-size: 0;
  margin: -6px;
  &__item {
    margin: 6px;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  &__title {
    display: none;
    text-align: center;
    min-width: 40px;
    max-width: 150px;
    position: absolute;
    padding: 7px 4px;
    background-color: $theme-light-color;
    color: $theme-main-color;
    bottom: calc(100% + 10px);
    font-size: 0.85rem;
    left: 0;
    opacity: 0;
    transition: all 500ms;
    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 7px 0 7px;
      border-color: $theme-light-color transparent transparent transparent;
      top: 100%;
      left: 15px;
    }
  }
  &__item:hover &__title {
    opacity: 1;
    display: block;
  }
}