.content {
  margin-top: 40px;

  &__container {
    // extending bootstrap container
    // using when full width frames inside container are needed and there are no page__container active!
    @extend .container-fluid;
    max-width: $site-max-width;
  }

  /* Pages header and H1 title group */
  &__header {
    padding-bottom: 50px;

    &--xs {
      padding-bottom: 0;
    }
    &--lg {
      padding-bottom: 30px;
    }
  }

  &__title {
    display: inline-block;
    font-size: 2.1rem;
    line-height: 1.1;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__quote {
    color: $base-font-color-secondary;
  }

  &__hinfo {
    display: inline-block;
    font-size: 0.9em;
    color: #000;
    white-space: nowrap;

    &-number {
      font-weight: bold;
    }
  }

  /* Content univarsal wrapper for vertical space */
  &__row {
    padding-bottom: 50px;

    &:last-child {
      padding-bottom: 0;
    }

    &--sm {
      padding-bottom: 20px;
    }

    &--top-md {
      padding-top: 30px;
    }
  }
  &__contacts-info {
    margin-bottom: 30px;
  }
  &__contacts-item {
    font-size: 0;
  }
  &__contacts-icon {
    display: inline-block;
    vertical-align: top;
    fill: $theme-light-color;
    color: $theme-light-color;
    margin-right: 15px;
    &--address {
      width: 19px;
      height: 26px;
    }
    &--phone {
      width: 22px;
      height: 22px;
    }
  }
  &__contacts-text {
    display: inline-block;
    vertical-align: top;
    font-size: 1.2rem;
    color: #fff;
    line-height: 1.5;
  }

  &__category-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    margin: -15px;
    & > * {
      margin: 15px;
    }
  }

  &__category-item {
    width: 100%;
    @media (min-width: $screen-xs-min) {
      width: calc(50% - 30px);
    }
    @media (min-width: $screen-lg-min) {
      width: calc(33.333% - 30px);
    }
  }

  /* Content pagination wrapper */
  &__pagination {
    margin-top: 25px;
    text-align: center;
  }

  /* Content toolbar wrapper */
  &__toolbar {
    padding-bottom: 20px;
  }

  /* Content sidebar */
  &__sidebar {
    border-left: 1px solid $base-border-color;
    padding-left: $grid-gutter-width;

    &-item {
      padding-bottom: 20px;
    }
  }
  &__img {
    display: block;
    padding: 1em;
    border: 1px solid $base-border-color;
    max-width: 100%;
    max-height: 100%;

    margin-bottom: rem(20px);

    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
    }
  }
  &__catalog {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #fff;
    transition: all 500ms;
    &:hover {
      color: $theme-light-color;
      .content__catalog-img {
        transform: scale(1.1);
      }
    }
  }
  &__catalog-wrapper {
    font-size: 0;
    padding-bottom: 130%;
    position: relative;
    overflow: hidden;
  }
  &__catalog-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: all 3s;
  }
  &__catalog-title {
    background-color: $theme-third-color;
    padding: 10px 15px;
    font-size: 1rem;
  }
  &__scopeClient {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #fff;
    transition: all 500ms;
    background-color: $theme-third-color;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-left: 3px solid $theme-light-color;
      border-right: 3px solid $theme-light-color;
      transform: scale(1, 0);
      transition: all 500ms;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 3px solid $theme-light-color;
      border-bottom: 3px solid $theme-light-color;
      transform: scale(0, 1);
      transition: all 500ms;
    }
    &:hover {
      color: $theme-light-color;
      &::after, &::before {
        transform: scale(1);
        z-index: 15;
      }
      .content__scopeClient-img {
        transform: scale(1.1);
      }
    }
  }
  &__scopeClient-wrapper {
    font-size: 0;
    padding-bottom: 83%;
    position: relative;
    overflow: hidden;
  }
  &__scopeClient-title {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 210px;
    max-width: 270px;
    padding: 20px 15px;
    background-color: $theme-third-color;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  &__scopeClient-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: all 3s;
  }
  &__downloaded {
    overflow: auto;
    table{
      width: 100%;
      font-size: 1.2rem;
    }
    thead {
      background-color: $theme-secondary-color;
      color: $theme-main-color;
      text-align: left;
    }
    th {
      vertical-align: middle;
      padding: 15px 30px;
      border-bottom: 4px solid $theme-main-color;
      &:not(:last-child) {
        border-right: 1px solid $theme-main-color;
      }
    }
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $theme-main-color;
      }
    }
    td {
      vertical-align: middle;
      padding: 15px 30px;
      &:not(:last-child) {
        border-right: 1px solid $theme-main-color;
      }
    }
    tbody {
      tr {
        background-color: $theme-third-color;
        &:nth-child(2n) {
          background-color: $base-border-color;
        }
      }
    }
  }
  &__downloaded-link {
    display: block;
    color: $base-font-color-secondary;
    text-decoration: none;
    font-size: 0;
    transition: all 500ms;
    white-space: nowrap;
    &:hover {
      color: $theme-light-color;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
    }
  }
  &__downloaded-icon {
    display: inline-block;
    vertical-align: middle;
    width: 31px;
    height: 31px;
    margin-right: 20px;
  }
  &__certificate-image {
    font-size: 0;
  }

}