.paginator {

  $item-text-color:           $base-font-color-secondary;
  $item-text-color-active:    $theme-main-color;
  $item-width:                40px;
  $item-height:               35px;
  $item-bg:                   $theme-third-color;
  $item-bg-active:            $theme-light-color;
  $item-link-color:           $base-font-color-secondary;

  &__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $item-width;
    height: $item-height;
    line-height: $item-height;
    background: $item-bg;
    color: $item-text-color;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 5px;
    }

    a {
      @include link-main;
      color: $item-link-color;
      @include position(absolute, 0 0 0 0);
    }

    &--active {
      color: $item-text-color-active;
      background-color: $item-bg-active;
    }

    &--ellipsis{
      border: 0;
    }

    &--first {

    }

    &--last {

    }

    &--next {

    }

    &--prev {

    }

  }

  &__arrow {
    display: block;
    //position: absolute;
    //top: 0;
    //bottom: 0;
    margin: 0 auto;

    width: 20px;
    fill: $base-font-color-secondary;
    color: $base-font-color-secondary;
    transition: all 300ms;
    &:hover {
      fill: $theme-light-color;
      color: $theme-light-color;
    }

    &--left {
      transform: scaleX(-1);
    }
  }

}