.board-nav {
  text-align: center;

  &__link {
    display: block;
    position: relative;
    font-size: 0;
  }

  &__header {
    font-size: 1rem;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    width: 180px;
    padding: 20px 30px;
    top: 0;
    background-color: $theme-third-color;
  }

  &__title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__imagebox {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
  }

  &__img {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: all 3s;
  }
  &__link:hover &__img {
    transform: scale(1.1);
  }

}