.search-nav {
  padding: 10px 0;
  background-color: $theme-secondary-color;

  &__item {

  }

  &__title {
    font-weight: bold;
    font-size: 0.9rem;
    display: block;
    padding: 10px;
    color: #000;
    text-transform: uppercase;

  }

  &__inner {
    padding: 0px 10px;
    font-size: 0.9rem;
    &-link {
      display: block;
      margin: 0 -10px;
      padding: 8px 10px;
      max-width: none;
      position: relative;
      color: #000;
      text-decoration: none;
      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        background-color: $theme-light-color;
        left: 0;
        top: 0;
        z-index: 5;
        transition: all 500ms;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
      &--active {
        &::before {
          width: 100%;
        }
      }
    }

    &-count {
      display: block;
      position: absolute;
      color: #9d9d9d;
      font-size: 0.9em;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      z-index: 10;
    }
    &-text {
      position: relative;
      z-index: 10;
    }
  }

}