.start-page{

  &__container{
    @extend .container-fluid;
    max-width: $site-max-width;
    &--xl {

      @media (min-width: $screen-lg-min){
        padding: 0;
        max-width: 1920px;
      }

    }
  }

  &__row{
    padding: 40px 0;
    background-color: $theme-third-color;
    &--black {
      background-color: $theme-main-color;
    }
  }
}