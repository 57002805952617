/*
--------------------------------------------------
Navigation menus styles
--------------------------------------------------
*/
.list-nav {


  //Elements
  &__items {
    font-size: 0;
    margin: -10px -20px;
    padding: 10px 0;
    @media (min-width: $site-max-width){
      padding: 0;
    }
  }

  &__item {
    display: inline-block;
    font-size: 1rem;
    vertical-align: top;

    position: relative;

    margin: 15px 20px;
    @media (min-width: $site-max-width){
      margin: 10px 20px;
      padding: 40px 0;
    }

    &:hover {
      .list-nav__link {
        color: $base-white-link-hover-color;
        &::after {
          width: 100%;
        }
      }
      .list-nav__arrow {
        transform: rotate(-180deg);
        fill: $base-white-link-hover-color;
        color: $base-white-link-hover-color;
      }
      .list-nav__drop {
        display: block;
      }
    }

    //Element Modifiers
    &--active {
      .list-nav__link {
        color: $base-white-link-hover-color;
        &::after {
          width: 100%;
        }
      }
      .list-nav__arrow{
        fill: $base-font-color-secondary;
      }
    }

  }

  &__link {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    @include link-white;
    border: 0;
    padding: 0px 10px;
    transition: all 500ms;
    &::after {
      position: absolute;
      content: '';
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background-color: $theme-light-color;
      transition: all 500ms;
      top: -15px;
      @media (min-width: $site-max-width){
        top: 0;
      }
    }

    &--empty {
      @include link-empty;
    }
  }

  &__ico {
    float: left;
    margin-right: 3px;

    &--caret-down {
      width: 8px;
      height: 8px;
      fill: $base-font-color-secondary;

      svg {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &__arrow{
    display: inline-block;
    fill: $base-white-link-color;
    color: $base-white-link-color;
    position: relative;
    top: -2px;
    transform: rotate(0deg);
    transition: all 500ms;

    &--down{
      font-size: 0;
      width: 8px;
      height: 5px;
    }
  }

  &__drop {
    display: none;

    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    //Element Modifiers
    &--rtl {
      right: 0;
      left: auto;
    }
  }

}