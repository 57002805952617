.header {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: -15px;
  & > * {
    padding: 15px;
  }
  &__logo {
    flex-shrink: 0;
    max-width: 170px;
    margin: 10px 0;
  }
  &__search-menu {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: -15px;
    @media (min-width: $screen-md-min){
      flex-shrink: 1;
      flex-grow: 1;
    }
    & > * {
      margin: 15px;
    }
  }

  &__menu {
    max-width: 1180px;
    padding: 0 35px;
  }
  &__search {
    flex-shrink: 0;
    font-size: 0;
    position: relative;
    @media (min-width: $screen-md-min) {
      padding-left: 35px;
      &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        background-color: $base-font-color;
        left: 0;
        top: 0;
      }
    }
    &-btn {
      padding: 10px;
      position: relative;
      &::after, &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        transition: transform 300ms;
      }
      &::after {
        border-top: 1px solid $theme-light-color;
        border-bottom: 1px solid $theme-light-color;
        transform: scale(0,1);

      }
      &::before {
        border-left: 1px solid $theme-light-color;
        border-right: 1px solid $theme-light-color;
        transform: scale(1,0);
      }

    }
    &-btn-wrapper {
      display: inline-block;
      vertical-align: top;
      width: 14px;
      height: 14px;
      position: relative;
    }
    &-icon {
      top: 0;
      left: 0;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid $base-font-color;
      transition: all 300ms;
      &::after {
        position: absolute;
        width: 6px;
        height: 3px;
        background-color: $base-font-color;
        content: '';
        border-radius: 10px;
        bottom: -2px;
        right: -4px;
        transform: rotate(45deg);
        transition: all 300ms;
      }
    }
    &-btn:hover {

      &::after, &::before {
        transform: scale(1);
      }

    }
    &-btn:hover &-icon {
      border-color: $theme-light-color;
      &::after {
        background-color: $theme-light-color;
      }
    }
  }
}
