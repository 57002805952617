.discount-table{
	display: table;
	width: 100%;
	
	&__row{
		display: table-row;

		&--header{
			font-weight: bold;
			
			.discount-table__item{
				
				&:after{
					content: '';
					display: block;
					height: 1px;
					border-top: 1px dashed $base-border-color;
					padding-bottom: 5px;
				}
			}		
		}
	}

	&__item{
		display: table-cell;
		vertical-align: top;
		padding: 2px 4px;
	}	

}