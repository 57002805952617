/*
--------------------------------------------------
Navigation table-navs styles
--------------------------------------------------
*/
.table-nav {
  $z-intdex: $z-index-s;

  position: relative;
  border-right: 1px solid $theme-main-color;
  border-left: 1px solid $theme-main-color;

  //Modifiers
  &--equal {
    .table-nav__items {
      table-layout: fixed;
    }
  }

  //Elements
  &__items {
    display: table;
    width: 100%;
    height: 50px;
  }

  &__item {
    position: relative;
    display: table-cell;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid $table-nav-border-color;

    &:last-child {
      border-right: 0;
    }

    //Element Modifiers
    &.is-active{
      .table-nav__link {
        color: $table-nav-link-active-color;
      }
    }

    &:hover {
      .table-nav__link {
        color: $table-nav-link-hover-color;
      }
      .table-nav__link::after {
        width: 100%;
        opacity: 1;
      }
    }
  }

  &__link {
    font-size: 0;
    display: block;
    height: 100%;
    color: $table-nav-link-color;
    text-decoration: none;
    position: relative;
    transition: all 500ms;
    text-transform: uppercase;
    font-weight: 700;

    &::after {
      z-index: 5;
      position: absolute;
      content: '';
      opacity: 0;
      width: 0;
      height: 2px;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $theme-light-color;
      transition: all 500ms;
    }

    &::before {
      content: '';
      height: 100%;
      padding: 10px 0;
      display: inline-block;
      vertical-align: middle;
    }

    &-helper {
      position: relative;
      z-index: 10;
      display: inline-block;
      vertical-align: middle;
      padding: 5px;
      font-size: 0.85rem;
    }
  }

  &__drop {
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    visibility: hidden;
    opacity: 0;
    height:     1px;
    overflow:   hidden;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 0;
      left: auto;
    }
  }

  &__item:hover > &__drop {
    visibility: visible;
    opacity: 1;
    height:     auto;
    overflow:   visible;
  }

}

