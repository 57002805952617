.overlay {
  color: #fff;
  padding: 13px 0;
  border: 2px solid $overlay-border-color;
  text-align: left;
  background: $overlay-bg-color;
  &--no-padding {
    padding: 0;
  }

  &__item {
    position: relative;
    &::after {
      height: 100%;
      width: 0;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background-color: $overlay-link-hover-bg;
      transition: all 300ms;
      z-index: 5;
    }


    &:hover {
      &::after {
        width: 100%;
      }
      & > .overlay__drop {
        display: block;
      }
      & > .overlay__link {
        color: $overlay-link-hover-color;
      }
    }
  }

  &__link {
    position: relative;
    z-index: 10;
    display: block;
    padding: 12px 23px;
    white-space: nowrap;
    color: $overlay-link-color;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 300ms;
  }

  &__ico {
    float: left;
    margin-top: -1px;
    margin-right: 4px;
  }

  &__arrow {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -4px;
    display: inline-block;
    fill: $overlay-link-color;

    &--right{
      font-size: 0;
      width: 4px;
      height: 8px;
    }
  }

  &__drop {
    display: none;

    position: absolute;
    top: -15px;
    left: 100%;

    white-space: nowrap;
    &--equal-top {
      top: -2px;
    }

    //Element Modifiers
    &--rtl {
      right: 100%;
      left: auto;
    }
  }

}