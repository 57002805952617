.product-cut {
  text-decoration: none;
  height: 100%;
  background-color: #fff;
  position: relative;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 3px solid $theme-light-color;
    border-bottom: 3px solid $theme-light-color;
    transform: scale(0, 1);
    transition: all 500ms;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-left: 3px solid $theme-light-color;
    border-right: 3px solid $theme-light-color;
    transform: scale(1, 0);
    transition: all 500ms;
  }
  &:hover {
    &::after, &::before{
      transform: scale(1);
      z-index: 10;
    }
    .product-cut__more {
      color: $theme-light-color;
      &::after, &::before{
        background-color: $theme-light-color;
      }
    }
  }


  &__info {
    width: 64%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    &--full {
      width: 100%;
    }
  }
  &__addphoto-box {
    width: 36%;
    position: relative;
  }
  &__addphoto {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  &__photo {
    margin-bottom: 15px;
  }
  &--fix-title &__title{
    font-size: 21px;
    line-height: 21px;
    height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


  &__title {
    margin-bottom: 15px;
    color: $base-second-link-color;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
  &__shortdesc {
    margin-bottom: 15px;
    color: $base-font-color;
  }
  &__more-box {
    padding-top: 15px;
    padding-bottom: 5px;
    border-top: 1px solid $third-border-color;
  }
  &__more {
    color: $base-font-color-secondary;
    display: inline-block;
    vertical-align: top;
    padding-right: 13px;
    position: relative;
    transition: all 500ms;
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 4px;
      background-color: $base-font-color-secondary;
      transform: rotate(45deg);
      right: 0;
      bottom: 5px;
      transition: all 500ms;
    }
    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 4px;
      background-color: $base-font-color-secondary;
      transform: rotate(-45deg);
      right: 0;
      top: 6px;
      transition: all 500ms;
    }
  }


}